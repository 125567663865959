/* eslint jsx-a11y/no-autofocus: "off" */
/* eslint jsx-a11y/anchor-is-valid: "off" */
import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import ReactDOM from 'react-dom'
import LazyImage from 'mgr/lib/components/LazyImage'
import { TagElement } from 'mgr/lib/components/Tags'

const ModalTopBar = ({ onCloseClick }) => (
  <div className="row top-bar">
    <div className="col-xs-1 col-xs-offset-11 search-close-bar">
      <a onClick={onCloseClick} style={{ cursor: 'pointer' }}>
        <i className="svr-icon- svr-icon--close close-icon" style={{ color: '#FFFFFF' }} />
      </a>
    </div>
  </div>
)

export const SearchModal = ({ isModalOpen, onCloseClick, children, onKeyDown }) => {
  window.document.body.addEventListener('keydown', onKeyDown)

  return (
    <div className={`universal-search ${isModalOpen ? 'open' : 'closed'}`} style={{ overflowY: 'scroll' }}>
      <div className="container-fluid search-container">
        <ModalTopBar onCloseClick={onCloseClick} />
        {children}
      </div>
    </div>
  )
}

export const TabRow = ({ tabs, activeTab, onTabChange }) => {
  const colSize = Math.floor(12 / tabs.length)
  const colSizeClass = `col-xs-${colSize} tab`

  return (
    <div className="row  tab-bar visible-xs-block">
      {tabs.map(elem => {
        const tabChangeAdapter = () => {
          onTabChange(elem.action)
        }
        return (
          <div key={elem.action} className={colSizeClass}>
            <a className={`tab ${elem.action === activeTab ? 'active' : ''}`} onClick={tabChangeAdapter}>
              {elem.text}
            </a>
          </div>
        )
      })}

      <div className="row">
        <hr className="col-xs-12 spacer" style={{ marginTop: 0 }} />
      </div>
    </div>
  )
}

export const ResultRow = ({
  singleResult,
  onRequestClick,
  onReservationClick,
  onOrderClick,
  isRequestSlideoutAvailable,
  isReservationSlideoutAvailable,
  isOrderSlideoutAvailable,
  isSingleVenue,
  venueUrlKey,
  isSupaflyEnabled,
}) => {
  let resultType
  const isActual = singleResult.type === 'ACTUAL'
  const isRequest = singleResult.type === 'REQUEST'
  const isOrder = singleResult.type === 'ORDER'
  const isSameVenue = singleResult.venue_key === venueUrlKey

  if (isActual) {
    resultType = <i className="svr-icon- svr-icon--book" />
  } else if (isRequest) {
    resultType = <i className="svr-icon- svr-icon--request-line" />
  } else if (isOrder) {
    resultType = <i className="svr-icon- svr-icon--check-dropped" />
  } else {
    resultType = <i className="svr-icon- svr-icon--help" />
  }

  let vipFlagContents

  if (singleResult.is_client_vip) {
    vipFlagContents = <span className="vip-flag" />
  }
  const showFor = singleResult.arrival_time_display && singleResult.max_guests_short_formatted ? 'for' : ''
  const guestDisplay = ` ${showFor} ${singleResult.max_guests_short_formatted}`
  const isRedStatus =
    singleResult.status_formatted === 'Cancelled' ||
    singleResult.status_formatted === 'No Show' ||
    singleResult.status_formatted === 'Canceled'
  const resultStyle = { cursor: 'pointer' }
  let href
  let target
  let onClick
  if (isRequest && isRequestSlideoutAvailable && isSameVenue) {
    onClick = () => onRequestClick(singleResult.id)
  } else if (isActual && isReservationSlideoutAvailable && (isSameVenue || isSupaflyEnabled)) {
    onClick = () => onReservationClick(singleResult.id, singleResult.venue_id)
  } else if (isOrder && isOrderSlideoutAvailable) {
    onClick = () => onOrderClick(singleResult.id, singleResult.venue_id)
  } else {
    href = singleResult.detail_link
    target = '_blank'
  }

  const canceledStyle = isRedStatus ? { color: '#F75A6D' } : {}

  let venueName = null

  if (!isSingleVenue) {
    venueName = <div className="col-xs-12 col-sm-5 result-text venue-name">{singleResult.venue_name}</div>
  }
  let arrivalTimeDisplay
  if (isOrder) {
    arrivalTimeDisplay =
      desiredRangeFormat(singleResult.guest_receiving_datetime_local, singleResult.desired_buffer_minutes, singleResult.timezone.zone) ||
      singleResult.opened_time_formatted
  } else {
    arrivalTimeDisplay = `${singleResult.arrival_time_display} ${guestDisplay}`
  }
  return (
    <li className="row result res">
      <a style={resultStyle} target={target} href={href} onClick={onClick} rel="noreferrer">
        {vipFlagContents}
        <div className="col-xs-1 result-text result-type  result-icon">{resultType}</div>
        <div className="col-xs-5  col-lg-5">
          <div className="row">
            <div className={`col-xs-12 ${isSingleVenue ? 'col-sm-12' : 'col-sm-7'} client-name result-text name`}>
              {singleResult.client_display_name}
            </div>
            {venueName}
          </div>
        </div>

        <div className="col-xs-4">
          <div className="row">
            <div className="col-xs-12 col-sm-6 result-text display-date">{singleResult.date_formatted_short_abbrev_display_year}</div>
            <div className="col-xs-12 col-sm-6 result-text arrival-time">{arrivalTimeDisplay}</div>
          </div>
        </div>
        <div className="col-xs-2 col-lg-2 result-text status" style={canceledStyle}>
          {singleResult.status_formatted}
        </div>
      </a>
    </li>
  )
}

const orderTimeFormat = (ts, tz) => moment(ts).tz(tz).format('LT')
const desiredRangeFormat = (targetReadyDatetimeLocal, desiredBufferMinutes, tz) => {
  if (_.isNil(targetReadyDatetimeLocal)) {
    return ''
  }
  const endDatetimeLocal = moment(targetReadyDatetimeLocal).tz(tz).clone().add({ minutes: desiredBufferMinutes })
  const startFormatted = orderTimeFormat(targetReadyDatetimeLocal, tz)
  const endFormatted = orderTimeFormat(endDatetimeLocal, tz)
  return `${startFormatted} - ${endFormatted}`
}

export const CombinedBookingSearchResultsSection = ({
  pastBookings,
  futureBookings,
  activeTab,
  isRequestSlideoutAvailable,
  isReservationSlideoutAvailable,
  isOrderSlideoutAvailable,
  venueUrlKey,
  onReservationClick,
  onRequestClick,
  onOrderClick,
  isSingleVenue,
  isSupaflyEnabled,
  isOrderingEnabled,
  isFetchingBookingResults,
}) => {
  const bookingsToElements = bookings =>
    bookings.map(element => (
      <ResultRow
        key={element.id}
        singleResult={element}
        venueUrlKey={venueUrlKey}
        isRequestSlideoutAvailable={isRequestSlideoutAvailable}
        isReservationSlideoutAvailable={isReservationSlideoutAvailable}
        isOrderSlideoutAvailable={isOrderSlideoutAvailable}
        isSupaflyEnabled={isSupaflyEnabled}
        onReservationClick={onReservationClick}
        onOrderClick={onOrderClick}
        onRequestClick={onRequestClick}
        isSingleVenue={isSingleVenue}
      />
    ))

  const doHide = activeTabAction => (activeTab === activeTabAction ? 'col-xs-12' : 'hidden-xs')
  let futureBookingElements

  if (futureBookings.length > 0) {
    futureBookingElements = <ul>{bookingsToElements(futureBookings)}</ul>
  } else {
    futureBookingElements = (
      <div className="row">
        <h3 className="col-xs-12  no-results">
          <hr className="spacer hidden-xs" />
          {isFetchingBookingResults ? 'Loading...' : 'No Results'}
        </h3>
      </div>
    )
  }

  const entityNames = isOrderingEnabled ? 'reservations, requests & orders' : 'reservations & requests'
  const futureBookingElementsWithHeader = (
    <div className="row">
      <div className={`col-sm-12 ${doHide('upcoming')}`}>
        <div className="row">
          <h3 className="col-sm-12 hidden-xs search-result-header">{`Upcoming ${entityNames}`}</h3>
        </div>
        {futureBookingElements}
      </div>
    </div>
  )

  let pastBookingElements

  if (pastBookings.length > 0) {
    pastBookingElements = <ul>{bookingsToElements(pastBookings)}</ul>
  } else {
    pastBookingElements = (
      <div className="row">
        <h3 className="col-xs-12  no-results" style={{ marginBottom: '20px' }}>
          <hr className="spacer hidden-xs" />
          {isFetchingBookingResults ? 'Loading...' : 'No Results'}
        </h3>
      </div>
    )
  }

  const pastBookingElementsWithHeader = (
    <div className="row">
      <div className={`col-sm-12 ${doHide('past')}`}>
        <div className="row">
          <h3 className="col-sm-12 hidden-xs search-result-header">{`Past ${entityNames}`}</h3>
        </div>
        {pastBookingElements}
      </div>
    </div>
  )

  return (
    <div className="col-sm-7 col-xs-12">
      {futureBookingElementsWithHeader}
      {pastBookingElementsWithHeader}
    </div>
  )
}

export class SearchQueryArea extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidUpdate() {
    if (this.props.hasFocus) {
      const node = ReactDOM.findDOMNode(this.textInput)
      window.setTimeout(() => {
        node.focus()
      }, 0)
    }
  }

  render() {
    const style = { opacity: 0.8 }
    return (
      <div className="row search-entry">
        <div className="col-sm-9 col-xs-12 search-box" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: '2rem' }}>
          <i className="svr-icon- svr-icon--search search-icon text" />
          <input
            ref={input => {
              this.textInput = input
            }}
            value={this.props.searchQuery}
            type="text"
            name="common-query"
            placeholder="Search"
            id="common-query"
            className="search-input text"
            onChange={this.props.onSearchChange}
            autoFocus
          />
          <p
            style={{
              textAlign: 'right',
              color: '#FFFFFF',
              fontSize: '1rem',
              marginTop: '1rem',
            }}
          >
            <span style={style}>press</span> <strong style={{ fontWeight: '900', opacity: 1, color: '#FFFFFF' }}>ESC</strong>
            <span style={style}> to close</span>
          </p>
        </div>
      </div>
    )
  }
}

const ClientResult = ({ singleResult }) => {
  let clientImageContents
  const imageSizes = ['small', 'medium', 'large']
  const imageSrcs = []
  let vipFlagContents

  if (singleResult.is_vip) {
    vipFlagContents = <span className="vip-flag" />
  }
  imageSizes.forEach(elem => {
    if (singleResult.photos && singleResult.photos[elem]) {
      imageSrcs.push(singleResult.photos[elem])
    }
  })

  if (singleResult.photos) {
    // clientImageContents = <img src={singleResult.photos.small} className="client-image"
    //                             alt={singleResult.name_first_first_display}/>
    clientImageContents = <LazyImage src={imageSrcs} className="client-image" alt={singleResult.name_first_first_display} />
  } else {
    clientImageContents = <div className="client-image">{singleResult.initials}</div>
  }

  const emailContents = singleResult.email && (
    <div className="col-xs-12  col-lg-7 result-text client-email fs-hide">{singleResult.email}</div>
  )
  const phoneContents = singleResult.phone_number_formatted && (
    <div className="col-xs-12  col-lg-5  result-text client-phone fs-hide">{singleResult.phone_number_formatted}</div>
  )

  let visitsLanguage = ''

  if (singleResult.total_visits === 1) {
    visitsLanguage = 'VISIT'
  } else if (singleResult.total_visits > 1) {
    visitsLanguage = 'VISITS'
  }
  return (
    <li className="row result">
      <a href={singleResult.detail_link} target="_blank" rel="noreferrer">
        {vipFlagContents}
        <div className="col-xs-1" style={{ paddingRight: 0, paddingLeft: 0 }}>
          {clientImageContents}
        </div>
        <div className="col-xs-8  result-text">
          <div className="row">
            <div className="col-xs-12  col-lg-5 result-text name">{singleResult.name_first_first_display}</div>
            <div className="hidden-xs hidden-sm col-lg-7 result-text">{singleResult.title}</div>
          </div>
          <div className="row fs-hide">
            {phoneContents}
            {emailContents}
          </div>

          <div className="row">
            <div className="col-xs-12 client-tags">
              {(singleResult.client_tags_display || []).map(elem => {
                const key = `${elem.tag_group_id}##${elem.tag_name}`
                return <TagElement key={key} tagObj={elem} />
              })}
            </div>
          </div>
        </div>
        <div className="col-xs-3  result-text visits">
          <strong className="visit-number">{singleResult.total_visits > 0 ? singleResult.total_visits : ''}</strong> {visitsLanguage}
        </div>
      </a>
    </li>
  )
}

export const ClientSearchResultsArea = ({ clients, activeTab, isFetchingClientResults }) => {
  let clientSearchResults

  if (clients.length === 0) {
    clientSearchResults = (
      <div className="row">
        <h3 className="col-xs-12 spacer no-results">
          <hr className="spacer hidden-xs" />
          {isFetchingClientResults ? 'Loading...' : 'No Results'}
        </h3>
      </div>
    )
  } else {
    clientSearchResults = (
      <ul>
        {' '}
        {clients.map(elem => (
          <ClientResult key={elem.id} singleResult={elem} />
        ))}
      </ul>
    )
  }

  const tabClass = `col-sm-4  col-sm-offset-1 ${activeTab === 'clients' ? 'col-xs-12' : 'hidden-xs'}`

  return (
    <div className={tabClass}>
      <div className="row">
        <h3 className="col-sm-12 hidden-xs search-result-header">Clients</h3>
      </div>
      {clientSearchResults}
    </div>
  )
}
