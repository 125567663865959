import {
  OPEN_SEARCH_MODAL,
  CLOSE_SEARCH_MODAL,
  END_BOOKING_SEARCH_RESULT_FETCH,
  DISPATCH_MULTI_SEARCH,
  INIT,
  END_CLIENT_SEARCH_RESULT_FETCH,
  CHOOSE_TAB,
} from '../actions/ActionTypes'

const initialUniversalSearchState = {
  searchQuery: '',
  clients: [],
  isFetchingClientResults: false,
  isFetchingBookingResults: false,
  isModalOpen: false,
  futureBookings: [],
  pastBookings: [],
  numFound: 0,
  numberOfClientsFound: 0,
  venueKey: '',
  activeTab: 'upcoming',
  isBlank: true,
  isSingleVenue: false,
  isReservationSlideoutAvailable: false,
  isRequestSlideoutAvailable: false,
  isOrderSlideoutAvailable: false,
  tabs: [
    { action: 'upcoming', text: 'Upcoming' },
    { action: 'past', text: 'Past' },
    {
      action: 'clients',
      text: 'Clients',
    },
  ],
}

const appState = (state = initialUniversalSearchState, action) => {
  switch (action.type) {
    case DISPATCH_MULTI_SEARCH: {
      const { searchQuery, isBlank } = action
      return {
        ...state,
        isBlank,
        searchQuery,
        futureBookings: [],
        pastBookings: [],
        numFound: 0,
        numberOfClientsFound: 0,
        clients: [],
        isFetchingClientResults: true,
        isFetchingBookingResults: true,
      }
    }
    case OPEN_SEARCH_MODAL:
      return { ...state, isModalOpen: true }
    case CLOSE_SEARCH_MODAL:
      // Naughty, Naughty...
      window.unsetBodyOverflow()
      return { ...state, isModalOpen: false }
    case INIT: {
      const { venueKey, isReservationSlideoutAvailable, isRequestSlideoutAvailable, isOrderSlideoutAvailable } = action.initialValues
      return {
        ...state,
        venueKey,
        isRequestSlideoutAvailable,
        isReservationSlideoutAvailable,
        isOrderSlideoutAvailable,
      }
    }
    case END_BOOKING_SEARCH_RESULT_FETCH: {
      const { pastBookings, futureBookings, isSingleVenue, numFound } = action
      return {
        ...state,
        pastBookings,
        isSingleVenue,
        futureBookings,
        numFound,
        isBlank: false,
        isFetchingBookingResults: false,
      }
    }
    case END_CLIENT_SEARCH_RESULT_FETCH: {
      const { clients, numberOfClientsFound } = action
      return {
        ...state,
        clients,
        numberOfClientsFound,
        isBlank: false,
        isFetchingClientResults: false,
      }
    }
    case CHOOSE_TAB: {
      const { activeTab } = action
      return { ...state, activeTab }
    }
    default:
      return state
  }
}

export default appState
