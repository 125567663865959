import React, { Component } from 'react'
import { ThemeProvider } from 'styled-components'
import Theme from 'mgr/layout/Theme'
import {
  ExpandableSearchModal,
  RenderedCombinedBookingSearchResultsSection,
  RenderedSearchQuery,
  WrappedClientSearchResultArea,
  MobileSearchTabBar,
} from '../containers/containers'

class App extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let mainBody

    if (!this.props.isBlank) {
      mainBody = [
        <MobileSearchTabBar key="mobile-tab-bar" />,
        <div key="result-area" className="row result-area">
          <RenderedCombinedBookingSearchResultsSection />
          <WrappedClientSearchResultArea />
        </div>,
      ]
    } else {
      mainBody = null
    }

    return (
      <ThemeProvider theme={Theme}>
        <ExpandableSearchModal>
          <RenderedSearchQuery />
          {mainBody}
        </ExpandableSearchModal>
      </ThemeProvider>
    )
  }
}

export default App
