import { srGet } from '@sevenrooms/core/api'
import {
  END_BOOKING_SEARCH_RESULT_FETCH,
  BOOKING_SEARCH_RESULT_FETCH_ERROR,
  END_CLIENT_SEARCH_RESULT_FETCH,
  CLIENT_SEARCH_RESULT_FETCH_ERROR,
} from './ActionTypes'

const searchResponseHandlerFactory = path => (venueKey, searchQuery, errHandler) =>
  srGet(path, {
    venue: venueKey,
    q: searchQuery,
  }).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response)
    }
    return response.data
  })

const fetchSearchResponse = searchResponseHandlerFactory('/api-yoa/search')
const fetchClientResponse = searchResponseHandlerFactory('/api-yoa/clients')

export const fetchBookingSearchResults = searchQuery => (dispatch, getState) => {
  const errHandler = () => {
    dispatch({ type: BOOKING_SEARCH_RESULT_FETCH_ERROR })
  }

  const state = getState()
  fetchSearchResponse(state.venueKey, searchQuery, errHandler).then(data => {
    const latestStore = getState()
    if (searchQuery !== latestStore.searchQuery) {
      window.svrDebug('Ignoring slow request response for fetchBookingSearchResults')
      return
    }
    const { pastBookings, futureBookings, numFound, isSingleVenue } = data
    dispatch({
      type: END_BOOKING_SEARCH_RESULT_FETCH,
      pastBookings,
      futureBookings,
      numFound,
      isSingleVenue,
    })
  })
}

export const fetchClientSearchResults = searchQuery => (dispatch, getState) => {
  const errHandler = () => {
    dispatch({ type: CLIENT_SEARCH_RESULT_FETCH_ERROR })
  }

  const state = getState()
  fetchClientResponse(state.venueKey, searchQuery, errHandler).then(data => {
    const latestStore = getState()
    if (searchQuery !== latestStore.searchQuery) {
      window.svrDebug('Ignoring slow request response for fetchClientSearchResults')
      return
    }
    const { num_found, clients } = data
    dispatch({
      type: END_CLIENT_SEARCH_RESULT_FETCH,
      clients,
      numberOfClientsFound: num_found,
    })
  })
}
