import React from 'react'
import ReactDOM from 'react-dom'
import { connect, Provider } from 'react-redux'
import { INIT, OPEN_SEARCH_MODAL } from './actions/ActionTypes'
import App from './components/app'
import store from './stores'

window.universalSearchBootstrap = function universalSearchBootstrap() {
  if (!window) {
    return
  }

  store.dispatch({ type: OPEN_SEARCH_MODAL })

  const initialValues = {
    venueKey: window.globalInit.venueUrlKey || window.Pmp.Manager.Global._url_key_or_id,
    isReservationSlideoutAvailable:
      !!window.ReservationSlideOut && !!window.ReservationSlideOut.base_url && !window.RequestSlideout.base_url,
    isRequestSlideoutAvailable: !!window.RequestSlideout && !!window.RequestSlideout.base_url,
    isOrderSlideoutAvailable: !!window.SvrManager.OrderSlideout,
  }
  store.dispatch({ type: INIT, initialValues })

  $('body').css({ overflow: 'hidden' })
  $('#mobile-bar').css({ 'z-index': '359' })
  if (window.navigator.hasOwnProperty('vibrate')) {
    window.navigator.vibrate(35)
  }
}

window.unsetBodyOverflow = function unsetBodyOverflow() {
  $('body').css({ overflow: 'auto' })
  $('#mobile-bar').css({ 'z-index': '999' })
}

const appLevelStateToProps = state => ({
  isBlank: state.isBlank,
})

const appLevelDispatchToProps = () => ({})

const WrappedApp = connect(appLevelStateToProps, appLevelDispatchToProps)(App)

$(() => {
  ReactDOM.render(
    <Provider store={store}>
      <WrappedApp globalInit={window.globalInit} />
    </Provider>,
    window.document.getElementById('universal-search')
  )
})
