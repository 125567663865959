import _ from 'lodash'
import { connect } from 'react-redux'
import { fetchBookingSearchResults, fetchClientSearchResults } from '../actions/actions'
import { CLOSE_SEARCH_MODAL, DISPATCH_MULTI_SEARCH, CHOOSE_TAB } from '../actions/ActionTypes'
import {
  SearchModal,
  CombinedBookingSearchResultsSection,
  SearchQueryArea,
  ClientSearchResultsArea,
  TabRow,
} from '../components/components'

const mapStateToProps = state => ({
  isModalOpen: state.isModalOpen,
})

const mapDispatchToProps = dispatch => ({
  onCloseClick: () => {
    dispatch({ type: CLOSE_SEARCH_MODAL })
  },
  onKeyDown: event => {
    if (event.keyCode === 27) {
      dispatch({ type: CLOSE_SEARCH_MODAL })
    }
  },
})

export const ExpandableSearchModal = connect(mapStateToProps, mapDispatchToProps)(SearchModal)

const bookingStateToProps = state => ({
  futureBookings: state.futureBookings,
  pastBookings: state.pastBookings,
  activeTab: state.activeTab,
  isReservationSlideoutAvailable: state.isReservationSlideoutAvailable,
  isRequestSlideoutAvailable: state.isRequestSlideoutAvailable,
  isOrderSlideoutAvailable: state.isOrderSlideoutAvailable,
  venueUrlKey: state.venueKey,
  searchQuery: state.searchQuery,
  isSingleVenue: state.isSingleVenue,
  hasMoreResults: state.numFound > 30,
  isSupaflyEnabled: !!window.globalInit.venueSettings.use_supafly,
  isOrderingEnabled: !!window.globalInit.venueSettings.is_orders_integration_enabled,
  isFetchingBookingResults: state.isFetchingBookingResults,
})

const bookingDispatchToProps = () => ({
  onReservationClick: (actualId, venueId) => {
    // eslint-disable-next-line no-undef
    closeAllSlideouts()
    if (window.globalInit.venueSettings.use_supafly) {
      window.SvrManager.ActualSlideout.viewActual(actualId, venueId)
    } else {
      window.ReservationSlideOut.showReservation(actualId)
    }
  },
  onRequestClick: requestId => {
    // eslint-disable-next-line no-undef
    window.RequestSlideout.loadrequest(requestId)
  },
  onOrderClick: (orderId, venueId) => {
    // eslint-disable-next-line no-undef
    closeAllSlideouts()
    window.SvrManager.OrderSlideout.viewOrder(venueId, orderId)
  },
})

const closeAllSlideouts = () => {
  window.SvrManager.ActualSlideout.closeSlideout()
  window.SvrManager.OrderSlideout.closeSlideout()
}

const mobileUniversalSearchTabBarStateToProps = state => ({
  tabs: state.tabs,
  activeTab: state.activeTab,
})

const mobileUniversalTabBarDispatchToProps = dispatch => ({
  onTabChange: activeTab => {
    dispatch({ type: CHOOSE_TAB, activeTab })
  },
})

export const MobileSearchTabBar = connect(mobileUniversalSearchTabBarStateToProps, mobileUniversalTabBarDispatchToProps)(TabRow)

const searchQueryStateToProps = state => ({
  searchQuery: state.searchQuery,
  hasFocus: state.isModalOpen,
})

const massDispatch = (_dispatch, val) => {
  _dispatch(fetchClientSearchResults(val))
  _dispatch(fetchBookingSearchResults(val))
}

const minLength = 2
const bounceDelay = 400
const debouncedSearchQueryResultDispatch = _.debounce(massDispatch, bounceDelay)
const searchQueryDispatchToProps = dispatch => ({
  onSearchChange: event => {
    const val = event.target.value
    const cleansedVal = val.trim()
    const isMinLength = cleansedVal.length >= minLength
    dispatch({
      type: DISPATCH_MULTI_SEARCH,
      searchQuery: val,
      isBlank: !isMinLength,
    })

    if (isMinLength) {
      debouncedSearchQueryResultDispatch(dispatch, val)
    }
  },
})

const clientResultsStateToProps = state => ({
  clients: state.clients,
  activeTab: state.activeTab,
  isFetchingClientResults: state.isFetchingClientResults,
})

export const RenderedSearchQuery = connect(searchQueryStateToProps, searchQueryDispatchToProps)(SearchQueryArea)

export const RenderedCombinedBookingSearchResultsSection = connect(
  bookingStateToProps,
  bookingDispatchToProps
)(CombinedBookingSearchResultsSection)

export const WrappedClientSearchResultArea = connect(clientResultsStateToProps)(ClientSearchResultsArea)
